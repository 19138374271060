import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { getUrlQueryParamValue } from '@wix/bookings-catalog-calendar-viewer-utils';
import { IWixAPI } from '@wix/native-components-infra/dist/es/src/types/types';

export type ResourceQueryParam = Partial<
  Record<
    BookingsQueryParams.STAFF | BookingsQueryParams.RESOURCE,
    string | undefined
  >
>;

export const getResourceQueryParam = (
  wixSdkAdapter: WixOOISDKAdapter,
  wixCodeApi: IWixAPI,
  isUseUtilsInsteadOfWixSDKEnabled: boolean,
): ResourceQueryParam => {
  const preSelectedStaff = isUseUtilsInsteadOfWixSDKEnabled
    ? getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.STAFF)
    : wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.STAFF);
  if (preSelectedStaff) {
    return { [BookingsQueryParams.STAFF]: preSelectedStaff };
  }
  const preSelectedResource = isUseUtilsInsteadOfWixSDKEnabled
    ? getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.RESOURCE)
    : wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.RESOURCE);
  if (preSelectedResource) {
    return { [BookingsQueryParams.RESOURCE]: preSelectedResource };
  }
  return {};
};
