import {
  WixOOISDKAdapter,
  BookingsQueryParams,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  CatalogServiceDto,
  ServiceLocation,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';

import settingsParams from '../../components/BookOnline/settingsParams';
import { FilterOption, FilterServicesByOptions } from '../../types/types';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import { getUrlQueryParamValue } from '@wix/bookings-catalog-calendar-viewer-utils';

export const createFilterOptions = ({
  filteredResources,
  wixSdkAdapter,
  flowAPI,
}: {
  filteredResources: {
    offerings: CatalogServiceDto[];
    categories: OfferingCategoryDto[];
    locations: ServiceLocation[];
  };
  flowAPI: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
}): FilterOption[] => {
  const {
    settings,
    translations: { t },
  } = flowAPI;

  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  let filterOptions: FilterOption[];

  if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
    filterOptions = filteredResources.categories.map((category: any) => {
      return {
        id: category.id,
        title: category.name,
        isSelected: false,
      };
    });
  } else {
    const sortLocationsAlphabetically = (location1: any, location2: any) =>
      location1.title > location2.title ? 1 : -1;
    const sortedFilterLocations = filteredResources.locations
      .map((location: ServiceLocation) => {
        return {
          id: location.businessLocation?.id || '',
          title: location.businessLocation?.name || '',
          isSelected: false,
        };
      })
      .sort(sortLocationsAlphabetically);
    const shouldShowOtherLocations = filteredResources.offerings.some(
      (service) =>
        service.info.locations?.find(
          (location) => location.type !== 'OWNER_BUSINESS',
        ),
    );
    filterOptions = [
      ...sortedFilterLocations,
      ...(shouldShowOtherLocations
        ? [
            {
              id: ReservedLocationIds.OTHER_LOCATIONS,
              title: t(
                'service-list.categories.location-categories.other-locations',
              ),
              isSelected: false,
            },
          ]
        : []),
    ];
  }
  const showAllServicesFilterOption =
    settings.get(settingsParams.showAllServicesFilterOption) &&
    filterOptions.length > 1;

  const preSelectedCategory = flowAPI.experiments.enabled(
    'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
  )
    ? getUrlQueryParamValue(
        flowAPI.controllerConfig.wixCodeApi,
        REQUESTED_CATEGORIES_URL_PARAM_NAME as BookingsQueryParams,
      )
    : wixSdkAdapter.getUrlQueryParamValue(
        REQUESTED_CATEGORIES_URL_PARAM_NAME as BookingsQueryParams,
      );
  const hasCategoryDeepLink = !!preSelectedCategory;
  if (showAllServicesFilterOption) {
    filterOptions.unshift({
      id: ALL_SERVICES,
      title:
        settings.get(settingsParams.allServices) ||
        t('category-name.all-services'),
      isSelected: true,
    });
  } else if (filterOptions.length > 0) {
    filterOptions[0].isSelected = true;
  }

  if (
    hasCategoryDeepLink &&
    filterServicesBy === FilterServicesByOptions.CATEGORIES
  ) {
    filterOptions.forEach((filterOption) => {
      filterOption.isSelected = filterOption.id === preSelectedCategory;
    });
    if (
      filterOptions.every((filterOption) => !filterOption.isSelected) &&
      filterOptions.length > 0
    ) {
      filterOptions[0].isSelected = true;
    }
  }

  return filterOptions;
};
