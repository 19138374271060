import { ISettingsGetter } from '@wix/tpa-settings';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { CatalogServiceDto } from '@wix/bookings-uou-types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import settingsParams from '../../components/BookOnline/settingsParams';
import stylesParams from '../../components/BookOnline/stylesParams';
import { ServiceListLayoutOptions } from '../../types/types';
import {
  CARD_MAX_WIDTH,
  CARD_MIN_WIDTH,
  GRID_CARD_MIN_WIDTH,
  MOBILE_CARD_MIN_WIDTH,
  SINGLE_CARD_PER_ROW,
} from '../../consts';
import { showExplorePlansForService } from '../../utils/serviceDetails/showExplorePlansForService';
import { isPricingPlanInstalled } from '@wix/bookings-catalog-calendar-viewer-utils';

export type BodyViewModel = {
  spaceBetweenCards: number;
  maxCardsPerRow: number;
  cardMinWidth: number;
  cardMaxWidth?: number;
  showDivider: boolean;
  dividerWidth: string;
  imageRatio: string;
  atLeastOneServiceHasExplorePlansLink: boolean;
};

export const createBodyViewModel = async ({
  flowAPI,
  wixSdkAdapter,
  scale,
  imageAspectRatio,
  serviceListLayout,
  services,
}: {
  flowAPI: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  scale: number;
  imageAspectRatio?: number;
  serviceListLayout: ServiceListLayoutOptions;
  services: CatalogServiceDto[];
}): Promise<BodyViewModel> => {
  const {
    settings,
    environment: { isMobile },
    experiments,
    controllerConfig: { wixCodeApi },
  } = flowAPI;
  const isUseUtilsInsteadOfWixSDKEnabled = experiments.enabled(
    'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
  );

  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const isStripLayout = serviceListLayout === ServiceListLayoutOptions.STRIP;

  const spaceBetweenCards = getSpaceBetweenCards(serviceListLayout, settings);

  const maxCardsPerRow = !isGridLayout
    ? SINGLE_CARD_PER_ROW
    : settings.get(settingsParams.cardsPerRow);

  const cardMinWidth = getCardMinWidth(isMobile, isGridLayout, scale);
  const cardMaxWidth = !isMobile && isGridLayout ? CARD_MAX_WIDTH : undefined;

  const showDivider =
    isStripLayout && settings.get(settingsParams.isServicesDividerVisible);

  const dividerWidth = getDividerWidth(
    isStripLayout,
    settings.get(settingsParams.servicesDividerWidth),
  );

  const imageRatio = getImageAspectRatio(isGridLayout, imageAspectRatio);

  const atLeastOneServiceHasExplorePlansLink =
    (isUseUtilsInsteadOfWixSDKEnabled
      ? await isPricingPlanInstalled(wixCodeApi)
      : await wixSdkAdapter.isPricingPlanInstalled()) &&
    (settings.get(settingsParams.isExplorePlansVisible) === true ||
      flowAPI.controllerConfig.config.allStyles.some(
        (style) => style.booleans[stylesParams.isExplorePlansVisible.key!],
      )) &&
    services.some(showExplorePlansForService);

  return {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
    atLeastOneServiceHasExplorePlansLink,
  };
};

const getSpaceBetweenCards = (
  serviceListLayout: ServiceListLayoutOptions,
  settings: { get: ISettingsGetter },
) => {
  switch (serviceListLayout) {
    case ServiceListLayoutOptions.GRID:
      return settings.get(settingsParams.gridCardsSpacing);
    case ServiceListLayoutOptions.STRIP:
      return settings.get(settingsParams.stripCardSpacing);
    case ServiceListLayoutOptions.CLASSIC:
    case ServiceListLayoutOptions.OVERLAPPING:
    default:
      return settings.get(settingsParams.cardSpacing);
  }
};

const getCardMinWidth = (
  isMobile: boolean,
  isGridLayout: boolean,
  scale: number,
) => {
  if (isMobile) {
    return MOBILE_CARD_MIN_WIDTH;
  }
  if (isGridLayout) {
    return GRID_CARD_MIN_WIDTH;
  }

  return CARD_MIN_WIDTH * scale;
};

const getImageAspectRatio = (
  isGridLayout: boolean,
  imageAspectRatio?: number,
) => {
  if (imageAspectRatio) {
    return `${Math.round(100 / imageAspectRatio)}%`;
  }

  return `${isGridLayout ? 100 : 0}%`;
};

const getDividerWidth = (
  isStripLayout: boolean,
  servicesDividerWidth: number,
): string => `${isStripLayout ? servicesDividerWidth : 0}px`;
