import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  BOOKINGS_FES_BASE_DOMAIN,
  BOOKINGS_WIDGET_BASE_DOMAIN,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../consts';
import { WidgetData } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';
import { getResourceQueryParam } from '../utils/queryParams/queryParams';
import { getSiteRolloutStatusForV1Shutdown } from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import settingsParams from '../components/BookOnline/settingsParams';
import { getPresetId } from '../components/BookOnline/controllerPrePageReady';
import { DisplayServicesByOptions } from '../types/types';
import {
  getServerBaseUrl,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const REVISION_HEADER_NAME = 'x-wix-site-revision';

export class BookingsApi {
  private authorization: string;
  private wixSdkAdapter: WixOOISDKAdapter;
  private readonly flowAPI: ControllerFlowAPI;
  private serverBaseUrl: string;
  private shouldWorkWithAppSettings: boolean;
  private useOneLinerInsteadOfWixSDK: boolean;
  private isUseUtilsInsteadOfWixSDKEnabled: boolean;
  constructor({
    flowAPI,
    wixSdkAdapter,
    baseUrl,
    authorization,
    shouldWorkWithAppSettings = true,
  }: {
    flowAPI: ControllerFlowAPI;
    wixSdkAdapter: WixOOISDKAdapter;
    baseUrl?: string;
    authorization?: string;
    shouldWorkWithAppSettings?: boolean;
  }) {
    this.authorization = authorization!;
    this.flowAPI = flowAPI;
    this.isUseUtilsInsteadOfWixSDKEnabled = flowAPI.experiments.enabled(
      'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
    );
    this.wixSdkAdapter = wixSdkAdapter;
    this.serverBaseUrl = this.isUseUtilsInsteadOfWixSDKEnabled
      ? getServerBaseUrl({
          wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
          appParams: flowAPI.controllerConfig.appParams,
        })
      : wixSdkAdapter.getServerBaseUrl();
    this.shouldWorkWithAppSettings = shouldWorkWithAppSettings;
    this.useOneLinerInsteadOfWixSDK = flowAPI.experiments.enabled(
      'specs.bookings.useOneLinerInsteadOfWixSDK',
    );
  }

  async notifyOwnerNonPremiumEnrollmentAttempt() {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/classes/nonPremium`,
      '',
      {
        headers: {
          [REVISION_HEADER_NAME]: this.useOneLinerInsteadOfWixSDK
            ? this.flowAPI.controllerConfig.wixCodeApi.site.revision
            : this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.useOneLinerInsteadOfWixSDK
            ? this.flowAPI.controllerConfig.platformAPIs.getCsrfToken()
            : this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]: this.useOneLinerInsteadOfWixSDK
            ? this.flowAPI.controllerConfig.wixCodeApi.site.revision
            : this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.useOneLinerInsteadOfWixSDK
            ? this.flowAPI.controllerConfig.platformAPIs.getCsrfToken()
            : this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async getWidgetData(): Promise<WidgetData> {
    const {
      environment: { isEditor, isSSR, isViewer, isPreview, isEditorX },
      httpClient,
      controllerConfig: { config, wixCodeApi, platformAPIs },
      experiments,
      settings,
    } = this.flowAPI;

    const buildServicesFilter = () => {
      if (isEditor || this.shouldWorkWithAppSettings) {
        return {};
      }

      if (
        [SINGLE_SERVICE_PRESET_ID, SINGLE_SERVICE_EDITOR_X_PRESET_ID].includes(
          getPresetId(config, isEditorX),
        )
      ) {
        const selectedService = settings.get(settingsParams.selectedService);
        if (selectedService) {
          return {
            serviceId: selectedService,
          };
        } else {
          return {
            limit: 1,
          };
        }
      }
      const displayServicesBy = settings.get(settingsParams.displayServicesBy);

      if (displayServicesBy === DisplayServicesByOptions.BY_SERVICES) {
        const selectedCategories = settings.get(
          settingsParams.selectedCategories,
        );

        if (selectedCategories.length) {
          return { categoryIds: selectedCategories.join(',') };
        }
      }

      if (
        experiments.enabled('specs.bookings.AllowToSelectBySpecificServices') &&
        displayServicesBy === DisplayServicesByOptions.BY_SPECIFIC_SERVICES
      ) {
        const selectedServices = settings.get(settingsParams.selectedServices);

        if (selectedServices.length) {
          return { serviceIds: selectedServices.join(',') };
        }
      }

      if (displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS) {
        const selectedLocations = settings.get(
          settingsParams.selectedLocations,
        );
        if (selectedLocations.length) {
          return { locationIds: selectedLocations.join(',') };
        }
      }

      return {};
    };

    const servicesFilter = buildServicesFilter();

    const isAnywhereInd = await isAnywhereFlow(
      wixCodeApi,
      experiments,
      isPreview,
    );
    const publicDataOverrideURL = isAnywhereInd
      ? this.isUseUtilsInsteadOfWixSDKEnabled
        ? getUrlQueryParamValue(
            wixCodeApi,
            BookingsQueryParams.PUBLIC_DATA_OVERRIDES,
          )
        : this.wixSdkAdapter.getUrlQueryParamValue(
            BookingsQueryParams.PUBLIC_DATA_OVERRIDES,
          )
      : undefined;
    const isLiveSiteNoSSR = isViewer && !isSSR;
    const metaSiteId = isLiveSiteNoSSR
      ? this.useOneLinerInsteadOfWixSDK
        ? platformAPIs.bi?.metaSiteId
        : this.wixSdkAdapter.getMetaSiteId()
      : '';
    const serverUrl = `${this.serverBaseUrl}${BOOKINGS_WIDGET_BASE_DOMAIN}`;
    const { data } = await httpClient.get<WidgetData>(serverUrl, {
      params: {
        viewMode: isEditor ? 'editor' : 'site',
        cacheId: metaSiteId,
        externalId: config.externalId,
        ...getResourceQueryParam(
          this.wixSdkAdapter,
          this.flowAPI.controllerConfig.wixCodeApi,
          this.isUseUtilsInsteadOfWixSDKEnabled,
        ),
        publicDataOverrides: publicDataOverrideURL,
        ...servicesFilter,
      },
    });
    return data;
  }

  async isEcomSite(): Promise<boolean> {
    try {
      const { data: response } = await this.flowAPI.httpClient.request(
        getSiteRolloutStatusForV1Shutdown({}),
      );
      return Boolean(response.siteRolloutStatus?.isBookingPlatformReady);
    } catch (e) {
      return true;
    }
  }
}
